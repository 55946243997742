import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

/**
 * Calls the backend api.
 * @returns {Object}.
 * @param {function} actionCreator
 */
export default function useFromApi(
  actionCreator,
  dependencyList = [],
  conditional = undefined,
  isConcat = false,
  resetDependencies = []
) {
  const [loading, setLoading] = useState(true);
  const [done, setDone] = useState(false);
  // Temporary sortOrder
  const [tempSortOrder, setTempSortOrder] = useState([]);
  // Main sortOrder that will be used
  const [sortOrder, setSortOrder] = useState([]);
  const [error, setError] = useState(undefined);

  const [refreshState, setRefreshState] = useState(true);

  const dispatch = useDispatch();

  function refresh() {
    setRefreshState(!refreshState);
  }

  useEffect(() => {
    if (conditional === undefined || conditional()) {
      setLoading(true);
      setDone(false);
      dispatch(actionCreator)
        .then((res) => {
          if (isConcat) {
            setTempSortOrder(res.data?.map((resource) => resource.id));
          } else {
            setSortOrder(res.data?.map((resource) => resource.id));
          }
        })
        .catch((err) => {
          dispatch({ type: "toaster/open", payload: {
            message: err.msg,
            severity: "error"
          } });
          setError(err);
        })
        .finally(() => {
          setLoading(false);
          setDone(true);
        });
    } // eslint-disable-next-line
  }, [dispatch, refreshState, ...dependencyList]);

  // resetDependencies list will reset the sortOrder
  useEffect(() => {
    setSortOrder([]);
  }, [dispatch, ...resetDependencies]);

  // Listen to changes to the temporary sortOrder,
  // then apply it accordingly
  useEffect(() => {
    // Concatinate to previous sortOrder
    setSortOrder(sortOrder.concat(tempSortOrder));
  }, [tempSortOrder]);

  /**
   * Loading tells whether call is still loading.
   * sortOrder tells the order of the resource's ids in data (not in included).
   * error is an object that contains the error from makeRequestThunkApi.
   * done tells whether or not the response has been returned.
   */
  return { loading, sortOrder, error, done, refresh };
}
