import { Box, FormHelperText, Typography } from '@mui/material';
import Dropzone from 'react-dropzone';
import timasColor from './timasColor';

function TimasDropzone(props) {
  const { 
    onDrop, 
    file, 
    rejectionMessage,
    error,
    inputProps
  } = props;

  return (
    <Dropzone 
      onDrop={onDrop} 
      { ...inputProps }
    >
      {(dz) => (
        <Box>
          <Box
            sx={{
              textAlign: "center",
              borderRadius: "16px",
              transition: "150ms",
              border: error && `1px solid ${timasColor.primary.main}`,
              background: dz.isDragReject 
                ? timasColor.primary[4] 
                : !dz.isDragActive && !dz.isFileDialogActive 
                  ? timasColor.white1 
                  : timasColor.secondary[4],
            }}
          >
            <Box 
              sx={{
                p: 8,
                cursor: "pointer"
              }}
              {...dz.getRootProps()}
            >
              <input {...dz.getInputProps()} />
              {
                file && (
                  <Typography >
                    {file.name} - {file.type} - {file.size}B
                  </Typography>
                )
              }
              <Typography>
                {
                  dz.isDragReject ? 
                  rejectionMessage || "Please input valid file type" :
                  dz.isDragActive ? 
                  "Drop your file here" : 
                  "Upload Your Resume Here or Click to Select a File"
                }
              </Typography>
            </Box>
          </Box>
          <FormHelperText error={true}>
            {error?.message}
          </FormHelperText>
        </Box>
      )}
    </Dropzone>
  );
}

export default TimasDropzone;