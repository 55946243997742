import { Box, Container, Slide, Typography } from '@mui/material';
import useScreenClass from 'hooks/useScreenClass';
import React, { useRef } from 'react';
import timasColor from './timasColor';
import { careers1 } from './timasImages';

function TimasCareers(props) {
  const rootRef = useRef(null);
  const sc = useScreenClass();
  const { backgroundUrl, title, description, children, backgroundSx } = props;

  return (
    <Box
      sx={{
        pt: sc.upToMd ? 16 : 30,
        pb: 16,
        px: sc.upToLg && sc.downToMd && 8,
        background: `${timasColor.blackGradient}, url(${backgroundUrl || careers1})`,
        backgroundBlendMode: "multiply",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        ...backgroundSx,
      }}
      ref={rootRef}
    >
      <Container>
        {
          children || 
          <>
            {
              title && (
                <Slide in={true} direction="down">
                  <Typography component="h1" variant="h6" color={timasColor.white1} sx={{paddingBottom: "16px"}}>
                    {title}
                  </Typography>
                </Slide>
              )
            }
            {
              description && (
                <Slide in={true} direction="up" container={rootRef.current} timeout={400}>
                  <Typography component="h2" variant={ sc.upToMd ? "h5" : "h4" }  color={timasColor.white}>
                    {description}
                  </Typography>
                </Slide>
              )
            }
          </>
        }
      </Container>
    </Box>
  );
}


export default TimasCareers;
