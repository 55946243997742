import { Button, Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PageScaffold from "components/PageScaffold";
import TimasBreadcrumbs from "components/TimasBreadcrumbs";
import timasColor from "components/timasColor";
import TimasHeader from "components/TimasHeader";
import TimasHero from "components/TimasHero";
import {
  cnc,
  cnccut,
  cncdril,
  davi,
  fabricationShop,
  fabricationShop1,
  fs2,
  prism1,
  prism2,
  wksarea,
  wksarea2,
  wksold
} from "components/timasImages";
import TransitionWhenVisible from "components/TransitionWhenVisible";
import useScreenClass from "hooks/useScreenClass";
import { Helmet } from "react-helmet";
import { IoDownload } from "react-icons/io5";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
const offshorefleetImages = [
  fs2,
  cnc,
  davi,
  cncdril,
  cnccut,
  wksarea,
  wksarea2
];

function FleetDescription() {
  const sc = useScreenClass();

  return (
    <Box
      sx={{
        background: `url(${prism1}) -60px bottom, url(${prism2}) right -40px top, ${timasColor.primary.gradient()}`,
        backgroundSize: "80%, 60%, 100%",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: "center",
        height: "100%",
        alignItems: "center",
        py: sc.upToMd && 2,
        px: sc.upToMd ? 2 : sc.upToLg ? 5 : 4
      }}
    >
      <Typography variant="h6" color={timasColor.white}>
        Established in 1993, our 20,000m2 Serang Workshop offers in-house
        fabrication and assembly
      </Typography>
    </Box>
  );
}

function FleetDetails(props) {
  const sc = useScreenClass();
  return (
    <Box
      sx={{
        background: timasColor.black,
        position: "relative",
        zIndex: "0",
        py: sc.upToMd ? 8 : 12
      }}
    >
      <Container
        sx={{
          zIndex: "2",
          color: timasColor.white
        }}
      >
        <Grid container>
          <Grid item sm={12} md={6}>
            <Typography variant="h6" sx={{ paddingBottom: "16px" }}>
              Fabrication Shop
            </Typography>
            <Typography variant={sc.upToMd ? "h4" : "h3"} marginBottom={8}>
              Spec Sheet
            </Typography>
            <Button
              fullWidth={sc.upToMd}
              endIcon={<IoDownload />}
              color="secondary"
              variant="contained"
              href="https://files.timas.com/index.php/s/FLEETFABRICATIONSHOP"
              target="_blank"
              rel="noreferrer"
            >
              Download PDF
            </Button>
          </Grid>
        </Grid>
      </Container>
      <img
        src={fabricationShop1}
        alt="Fleet Details"
        style={{
          position: "absolute",
          zIndex: "-1",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          opacity: 0.3
        }}
      />
    </Box>
  );
}

function FleetPage() {
  const sc = useScreenClass();

  return (
    <PageScaffold>
      <Helmet>
        <title>Fleet</title>
      </Helmet>
      <TimasHero
        title="Facilities"
        description="Fabrication Shop"
        backgroundUrl={fabricationShop}
      />
      <Container
        sx={{
          pb: 15
        }}
      >
        <TimasBreadcrumbs />
        <TransitionWhenVisible>
          <Box
            sx={{
              py: 20,
              minHeight: sc.upToMd && "400px"
            }}
          >
            <Grid
              container
              columnSpacing={2}
              sx={{
                position: "relative"
              }}
            >
              <Grid item xs={12} sm={12} md={8}>
                <img
                  src={wksold}
                  alt="Timas Fleet"
                  style={{
                    maxWidth: "100%",
                    objectFit: "cover"
                  }}
                />
              </Grid>
              {sc.downToMd ? (
                <Grid item xs sm md={4}>
                  <FleetDescription />
                </Grid>
              ) : (
                <Box
                  sx={{
                    position: "absolute",
                    top: "75%",
                    left: "50%",
                    width: "80%",
                    transform: "translate(-50%, 0)"
                  }}
                >
                  <FleetDescription />
                </Box>
              )}
            </Grid>
          </Box>
        </TransitionWhenVisible>
        <Grid container>
          <Grid item sm={12} md={4}>
            <TransitionWhenVisible direction="right">
              <TimasHeader variant="h2" text="Highlights" />
              <Typography marginTop={sc.upToMd ? 3 : 3} marginBottom={4}>
                We pride ourselves in the synergy established among all
                departments, from engineering, procurement, planning and quality
                assurance/ quality control, so that all our fabricated products
                could become industrial benchmarks in terms of quality,
                efficiency and workmanship.
              </Typography>
            </TransitionWhenVisible>
          </Grid>
          <Grid item xs={0} sm={0} md={1} />
          <Grid item xs={12} sm={12} md={7}>
            <TransitionWhenVisible direction="left">
              <Grid item sm={0} md={12}>
                <Swiper
                  navigation={{
                    clickable: true
                  }}
                  modules={[Pagination, Navigation]}
                  spaceBetween={30}
                >
                  {offshorefleetImages.map((image, idx) => (
                    <SwiperSlide key={idx} style={{ height: "auto" }}>
                      <img
                        src={image}
                        alt="Onshore Project"
                        style={{
                          width: "100%",
                          height: sc.upToMd ? "300px" : "100%",
                          objectFit: "cover",
                          objectPosition: "cover"
                        }}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Grid>
            </TransitionWhenVisible>
          </Grid>
        </Grid>
      </Container>
      <FleetDetails />
    </PageScaffold>
  );
}
const layouts = [
  {
    title: "2 Fabrication shop for carbon steel (crane cap.2 x 10 ton)",
    area: "1.584"
  },
  {
    title: "PWHT furnance facility (crane cap.1 x 10 ton)",
    area: "1.584"
  },
  {
    title: "PWHT furnance facility (crane cap.1 x 10 ton)",
    area: "1.584"
  },
  {
    title: "Shoot blasting room facility (crane cap.1 x 10 ton)",
    area: "1.584"
  },
  {
    title: "Painting area facility (crane cap.1 x 10 ton)",
    area: "1.584"
  },
  {
    title: "Main office building",
    area: "1.584"
  },
  {
    title: "Laydown area (crane cap.2 x 5 ton)",
    area: "1.584"
  },
  {
    title: "Warehouse for carbon steel material (crane cap.1 x 5 ton)",
    area: "1.584"
  },
  {
    title: "Production tool room",
    area: "1.584"
  }
];
export { layouts };
export default FleetPage;
