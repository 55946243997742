import { Box, Collapse, Fade, Slide, Zoom } from '@mui/material';
import React from 'react';
import { useInView } from 'react-intersection-observer';

function TransitionWhenVisible(props) {
  const { inView, ref } = useInView({
    triggerOnce: true,
    rootMargin: "0px 0px -300px"
  })
  const { 
    children, 
    transition,
    delay,
    direction,
    ...rest
  } = props;

  const defaultProps = {
    in: inView,
    container: ref.current
  }

  return (
    <Box 
      ref={ref} 
      {...rest}
    >
      {
        transition === "zoom" ? (
          <Zoom 
            { ...defaultProps }
          >
            <div>{children}</div>
          </Zoom>
        ) : transition === "slide" ? (
          <Slide 
            { ...defaultProps }
          >
            <div>{children}</div>
          </Slide>
        ) : transition === "fade" ? (
          <Fade 
          { ...defaultProps }
          >
            <div>{children}</div>
          </Fade>
        ) : transition === "collapse" ? (
          <Collapse 
            { ...defaultProps }
          >
            <div>{children}</div>
          </Collapse>
        ) : (
          <Slide 
            direction={direction || "up"} 
            { ...defaultProps }
          >
            <div>{children}</div>
          </Slide>
        )
      }
    </Box>
  );
}

export default TransitionWhenVisible;