import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  MenuItem,
  Modal,
  Popover,
  Slide,
  Typography
} from "@mui/material";
import useScreenClass from "hooks/useScreenClass";
import React, { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { IoMdMenu } from "react-icons/io";
import { Link, NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import timasLogoMobile from "../images/timas_logo.png";
import timasLogoCentered from "../images/timas_navbar_logo_centered.png";
import timasColor from "./timasColor";

const navs = [
  {
    title: "About Us",
    to: "/about",
    children: [
      { title: "Our Journey", to: "/about#our-journey" },
      { title: "Visions and Missions", to: "/about#vision-and-mission" },
      { title: "Our Values", to: "/about#our-values" },
      { title: "Fleet and Facilities", to: "/about#fleet-and-facilities" }
      //{ title: "Careers", to: "/about/careers" }
    ]
  },
  { title: "Services", to: "/services" },
  { title: "Awards", to: "/awards" },
  { title: "Projects", to: "/projects" },
  { title: "Careers", to: "/careers" },
  { title: "Contact Us", to: "/contact-us" }
];

function NavbarMobile(props) {
  const { scrollState } = props;
  const [navMobileOpen, setNavMobileOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(false);

  function handleOpenMobileNav() {
    setNavMobileOpen(true);
  }

  function handleCloseMobileNav() {
    setNavMobileOpen(false);
  }

  const handleSubmenuToggle = () => {
    setOpenSubmenu(!openSubmenu);
  };

  return (
    <>
      <Button
        variant="text"
        sx={{
          color: scrollState ? timasColor.black : timasColor.white
        }}
        onClick={handleOpenMobileNav}
      >
        <IoMdMenu />
      </Button>
      <Modal open={navMobileOpen} onClose={handleCloseMobileNav}>
        <Slide direction="left" in={navMobileOpen}>
          <Box
            sx={{
              position: "fixed",
              top: "0",
              right: "0",
              height: "100vh",
              // width: "80%",
              background: timasColor.white,
              color: timasColor.black,
              pt: 8,
              px: 4
            }}
          >
            {navs.map((n, idx) => (
              <Box key={idx}>
                <Box
                  sx={{
                    mb: 3,
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                  // onClick={handleCloseMobileNav}
                >
                  <NavLink
                    to={n.to}
                    onClick={handleCloseMobileNav}
                    style={({ isActive }) => ({
                      transition: "300ms",
                      color: isActive
                        ? timasColor.secondary[3]
                        : timasColor.black,
                      borderBottom:
                        isActive && `solid 2px ${timasColor.secondary[3]}`,
                      textDecoration: "none"
                    })}
                  >
                    {({ isActive }) => (
                      <Typography
                        variant="h6"
                        color="inherit"
                        sx={{
                          textDecoration: "none",
                          fontWeight: isActive ? 700 : 400
                        }}
                      >
                        {n.title}
                      </Typography>
                    )}
                  </NavLink>
                  {n.children && n.children.length && (
                    <FiChevronDown
                      onClick={handleSubmenuToggle}
                      size={24}
                      style={{
                        // color: timasColor.white,
                        marginLeft: "8px",
                        cursor: "pointer",
                        transition: "150ms",
                        transform: openSubmenu && "rotate(180deg)"
                      }}
                    />
                  )}
                </Box>
                {n.children && n.children.length && (
                  <Accordion
                    expanded={openSubmenu}
                    sx={{
                      "&:before": {
                        background: "none"
                      },
                      background: "none",
                      color: "white"
                    }}
                  >
                    <AccordionSummary
                      sx={{
                        height: "0px",
                        minHeight: "0px",
                        position: "static",
                        "&.Mui-expanded": {
                          minHeight: "0px"
                        }
                      }}
                    />
                    <AccordionDetails>
                      {n.children.map((c, idx) => (
                        <HashLink
                          key={idx}
                          to={c.to}
                          onClick={handleCloseMobileNav}
                          style={{
                            color: timasColor.black,
                            textDecoration: "none"
                          }}
                        >
                          <Typography
                            fontFamily="MicroExtendFLF, --apple-system, sans-serif"
                            sx={{
                              mb: 4
                            }}
                          >
                            {c.title}
                          </Typography>
                        </HashLink>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                )}
              </Box>
            ))}
          </Box>
        </Slide>
      </Modal>
    </>
  );
}

function Navbar(props) {
  let listener = null;
  const sc = useScreenClass();
  const [scrollState, setScrollState] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
    setOpenDropdown(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenDropdown(false);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    listener = document.addEventListener("scroll", () => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 120) {
        setScrollState(true);
      } else {
        setScrollState(false);
      }
    });
    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, [scrollState]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        position: "fixed",
        zIndex: 1000,
        height: "fit-content",
        transition: "300ms",
        background: scrollState && timasColor.white,
        boxShadow: scrollState && "0px 16px 24px rgba(37, 37, 37, 0.25)"
      }}
      paddingY={scrollState ? "8px" : "32px"}
    >
      <Link to="/">
        <img
          src={sc.upToMd ? timasLogoMobile : timasLogoCentered}
          alt="Timas Logo"
          style={{
            height: scrollState
              ? sc.upToMd
                ? "36px"
                : "48px"
              : sc.upToMd
              ? "48px"
              : "64px",
            paddingLeft: sc.upToMd ? (scrollState ? "16px" : "24px") : "0px",
            objectFit: "contain",
            transition: "300ms"
          }}
        />
      </Link>
      {sc.upToMd ? (
        <NavbarMobile scrollState={scrollState} />
      ) : (
        <div
          style={{
            display: "flex",
            gap: "24px",
            alignItems: "center",
            paddingRight: "54px"
          }}
        >
          {navs.map((n, idx) => (
            <div
              key={idx}
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <NavLink
                to={n.to}
                style={({ isActive }) => ({
                  transition: "300ms",
                  color: isActive
                    ? timasColor.secondary[3]
                    : scrollState
                    ? timasColor.black
                    : timasColor.white,
                  borderBottom:
                    isActive && `solid 2px ${timasColor.secondary[3]}`,
                  textDecoration: "none"
                })}
              >
                {({ isActive }) => (
                  <Typography
                    aria-haspopup="true"
                    sx={{
                      fontWeight: isActive ? 700 : 300,
                      fontSize: 12,
                      letterSpacing: "1px",
                      fontFamily: "MicroExtendFLF, --apple-system, sans-serif",
                      "&:hover": {
                        color: timasColor.secondary[3]
                      }
                    }}
                  >
                    {n.title}
                  </Typography>
                )}
              </NavLink>
              {n.children && (
                <React.Fragment>
                  <FiChevronDown
                    onMouseEnter={handlePopoverOpen}
                    onClick={handlePopoverOpen}
                    style={{
                      width: "16px",
                      color: scrollState ? timasColor.black : timasColor.white,
                      marginLeft: "8px",
                      cursor: "pointer",
                      transition: "150ms",
                      transform: openDropdown && "rotate(180deg)"
                    }}
                  />
                  <Popover
                    open={openDropdown}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    sx={{
                      mt: 2
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                  >
                    <Box onMouseLeave={handlePopoverClose} padding={2}>
                      {n.children.map((c, idx) => (
                        <MenuItem component={HashLink} key={idx} to={c.to}>
                          <Typography
                            sx={{
                              color: timasColor.black,
                              fontWeight: 600,
                              fontSize: 12,
                              letterSpacing: "0.8px",
                              fontFamily:
                                "MicroExtendFLF, --apple-system, sans-serif"
                            }}
                          >
                            {c.title}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Box>
                  </Popover>
                </React.Fragment>
              )}
            </div>
          ))}
        </div>
      )}
    </Box>
  );
}

export default Navbar;
