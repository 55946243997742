import { Container, Grid, Typography } from '@mui/material';
import PageScaffold from 'components/PageScaffold';
import TimasBreadcrumbs from 'components/TimasBreadcrumbs';
import timasColor from 'components/timasColor';
import TimasHero from 'components/TimasHero';
import React from 'react';
import { Helmet } from 'react-helmet';
import hssePolicy from 'images/timas-hsse-policy.png';
import { curve, imo, iso14001, iso45001, smk3, } from 'components/timasImages';
import { Box } from '@mui/system';

function HSSEPage() {
  return (
    <PageScaffold>
      <Helmet>
        <title>HSSE Management System</title>
        <meta property="og:title" content="Timas HSSE Management System" />
        <meta
          name="description"
          content="Timas is highly committed to protect the health, safety, security, and welfare of its personnel."
        />
        <meta
          name="og:description"
          content="Timas is highly committed to protect the health, safety, security, and welfare of its personnel."
        />
      </Helmet>
      <TimasHero
        title="Safety"
        description="HSSE Management System"
      />
      <Container>
        <TimasBreadcrumbs/>
        <Grid 
          container 
          spacing={8} 
          sx={{ 
            py: 12,
          }}
        >
          <Grid item sm={12} md={5}>
            <Box
              sx={{
                background: `url(${curve})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "100%",
                p: 3
              }}
            >
              <Typography variant="h3" color={timasColor.primary.dark} marginBottom={5}>
                Stop Accidents Before They Stop You
              </Typography>
              <Typography variant="body1" color={timasColor.grey} marginBottom={8}>
                <p>
                  Safety is our utmost priority, we are highly committed to protect the health, safety, security and welfare of our workers.
                </p>
              </Typography>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={6} sm={6} md={3}>
                  <img
                    src={iso14001}
                    alt="ISO 14001"
                    style={{
                      width: "100%",
                      height: "auto"
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <img
                    src={iso45001}
                    alt="ISO 45001"
                    style={{
                      width: "100%",
                      height: "auto"
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <img
                    src={smk3}
                    alt="SMK3"
                    style={{
                      width: "100%",
                      height: "auto"
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <img
                    src={imo}
                    alt="IMO"
                    style={{
                      width: "100%",
                      height: "auto"
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item sm={12} md={7}>
            <Typography marginBottom={5}>
              <p>
              It is that of TIMAS' vision and commitment to maintain an outstanding HSSE Performance in all of our operations. We have adopted a strict strategy outlined in our HSSE Policy statement.
              </p>
              <p>
              TIMAS's HSSE MS has been developed in accordance with all applicable global legislation and standards. TIMAS's HSSE MS consists of Level 1 and Level 2 HSSE Documents that have been certified to ISO 14001 and ISO 45001.
              </p>
            </Typography>
            <img
              src={hssePolicy}
              alt="Timas HSSE Policy"
              style={{
                width: "100%",
                height: "auto"
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </PageScaffold>
  );
}

export default HSSEPage;