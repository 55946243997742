import { Button, Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import fromApi from "actions/fromApi";
import PageScaffold from "components/PageScaffold";
import TimasBreadcrumbs from "components/TimasBreadcrumbs";
import TimasHero from "components/TimasCareers";
import timasColor from "components/timasColor";
import TimasSpinner from "components/TimasSpinner";
import useFromApi from "hooks/useFromApi";
import useResourceMapper from "hooks/useResourceMapper";
import useScreenClass from "hooks/useScreenClass";
import ProjectBodyMarkdown from "pages/ProjectDetailsPage/ProjectBodyMarkdown";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { MdArrowBack } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import ApplyCareer from "./ApplyCareer";

function CareersDetail() {
  const sc = useScreenClass();
  const { jobId } = useParams();
  const [isApplying, setIsApplying] = useState(false);

  function handleToggleApply() {
    setIsApplying(!isApplying);
  }

  const jobApi = useFromApi(fromApi.getJob(jobId), [jobId]);
  const job = useResourceMapper("jobs", jobApi.sortOrder)?.[0];

  return (
    <PageScaffold>
      <TimasHero
        title="Looks Like You've Got What it Takes!"
        description="Apply Now!"
      />
      <Container>
        {!jobApi.loading ? (
          job ? (
            <>
              <Helmet>
                <title>
                  {job.position} - {job.department}
                </title>
                <meta
                  property="og:title"
                  content={`${job.position} - ${job.department} - Timas Careers`}
                />
                <meta name="description" content={job.description} />
                <meta name="og:description" content={job.description} />
              </Helmet>
              <TimasBreadcrumbs
                lastTitle={`${job.position} - ${job.department}`}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: sc.upToMd && "column",
                  alignItems: !sc.upToMd && "center",
                  justifyContent: "space-between",
                  mb: 8
                }}
              >
                <div>
                  <Typography
                    variant="h4"
                    color={timasColor.primary.dark}
                    marginTop={3}
                    marginBottom={2}
                  >
                    {job.position}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "16px"
                    }}
                  >
                    <Typography>{job.department}</Typography>
                    <Typography>•</Typography>
                    <Typography>{job.division}</Typography>
                    <Typography>•</Typography>
                    <Typography>{job.location}</Typography>
                  </Box>
                </div>
                {new Date(job?.closedAt) > new Date() && (
                  <Button
                    fullWidth={sc.upToMd}
                    onClick={handleToggleApply}
                    variant={isApplying ? "outlined" : "contained"}
                    sx={{
                      mt: sc.upToMd && 4
                    }}
                    disabled={
                      new Date(job?.closedAt) <= new Date()
                    }
                  >
                    {isApplying ? "Cancel" : "Apply Now"}
                  </Button>
                )}
              </Box>
              <ApplyCareer
                expanded={isApplying}
                jobId={jobId}
                handleToggle={handleToggleApply}
              />
              <Typography variant="h6" marginBottom={2}>
                Job Description
              </Typography>
              {/* <Typography>
              {job.description}
            </Typography> */}
              <ProjectBodyMarkdown body={job.body} />
              <Container maxWidth="sm" sx={{ py: 8 }}></Container>
            </>
          ) : (
            <Box marginTop={8}>
              <Typography
                variant="h4"
                color={timasColor.primary.dark}
                marginBottom={2}
              >
                Job is not found!
              </Typography>
              <Button
                fullWidth={sc.upToMd}
                component={Link}
                to="/careers"
                color="primary"
                startIcon={<MdArrowBack />}
              >
                Back to Careers Openings
              </Button>
            </Box>
          )
        ) : (
          <TimasSpinner />
        )}
      </Container>
    </PageScaffold>
  );
}

export default CareersDetail;
