const toastReducer = (state = null, action) => {
  switch (action.type) {
    case `toaster/open`: {
      return { 
        ...state, 
        message: action.payload.message,
        severity: action.payload.severity
      }
    }
    case `toaster/close`: {
      return null;
    }
    default: {
      return state;
    }
  }
}

export default toastReducer;