import { yupResolver } from '@hookform/resolvers/yup';
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Container, FormControl, FormHelperText, Grid, Input, InputLabel, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import fromApi from 'actions/fromApi';
import timasColor from 'components/timasColor';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { date } from 'yup';
import { object, string } from 'yup';
import TimasDropzone from 'components/TimasDropzone';
import { curve } from 'components/timasImages';
import ReCAPTCHA from 'react-google-recaptcha';
import useScreenClass from 'hooks/useScreenClass';

function ApplyCareer(props) {
  const sc = useScreenClass();
  const dispatch = useDispatch();
  const { expanded, jobId, handleToggle } = props;
  const recaptchaRef = useRef(null);
  const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [file, setFile] = useState(null);

  function handleFileDrop(files) {
    setFile(files[0]);
  }

  const applicantSchema = object().shape({
    name: string().required("Please input your name"),
    email: string()
      .required("Please input your email")
      .email("Please input a valid email"),
    phoneNumber: string()
      .matches(/^\d+$/, "Please only input number")
      .max(13, "Maximum character is 13")
      .required("Please input your phone number"),
    address: string()
      .required("Please input your address"),
    jobId: string().default(jobId),
    birthday: date(),
  })
  const { 
    register, 
    handleSubmit, 
    formState: { errors }, 
    setError, 
    clearErrors
  } = useForm({ 
    resolver: yupResolver(applicantSchema) 
  });

  useEffect(() => {
    if (file) {
      clearErrors("cvUrl");
    } else {
      setError(
        "cvUrl",
        {
          type: "missing",
          message: "Upload is Required"
        }
      );
    }
  }, [clearErrors, file, setError])

  function onSubmit(data) {
    if (file) {
      setIsSubmitting(true);
      const recaptcha = process.env.REACT_APP_ENV !== "test"
        ? recaptchaRef.current.getValue()
        : null;
      const formData = new FormData();
      formData.append("file", file, file.name);
        dispatch(fromApi.uploadResume(formData))
        .then((res) => {
          const cvUrl = res.data[0].attributes.url;
          const payload = {
            ...data,
            cvUrl,
            recaptcha
          };
          dispatch(fromApi.createApplicant(payload))
            .catch((e) => {
              dispatch({ type: "toaster/open", payload: {
                message: e.msg, severity: "error"
              } })
              console.log(e);
            })
            .finally(() => {
              setIsSubmitting(false);
              setHasSubmitted(true);
            })
        })
        .catch((e) => {
          dispatch({ type: "toaster/open", payload: {
            message: e.msg, severity: "error"
          } })
          console.log(e);
        })
    }
  }

  return (
    <Accordion expanded={expanded} sx={{
      "&:before": {
        background: "none"
      }
    }}> 
      <AccordionSummary
        sx={{
          height: "0px",
          minHeight: "0px",
          position: "static",
          "&.Mui-expanded": {
            minHeight: "0px"
          }
        }}
      />
      <AccordionDetails>
        <Container 
          maxWidth="md"
          sx={{
            pb: 4,
            mb: 8,
            backgroundImage: `url(${curve})`,
            backgroundSize: "cover"
          }}
        >
          {
            !hasSubmitted ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid 
                  container 
                  rowSpacing={3} 
                  columnSpacing={4} 
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      label="Name" 
                      id="name"
                      helperText={errors?.name?.message}
                      error={Boolean(errors?.name)}
                      fullWidth
                      {...register("name")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      label="Email" 
                      id="email"
                      helperText={errors?.email?.message}
                      error={Boolean(errors?.email)}
                      fullWidth
                      {...register("email")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      label="Phone Number" 
                      id="phoneNumber"
                      helperText={errors?.phoneNumber?.message}
                      error={Boolean(errors?.phoneNumber)}
                      fullWidth
                      {...register("phoneNumber")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl variant="standard" fullWidth error={Boolean(errors?.birthday)}>
                      <InputLabel shrink htmlFor="birthday">Birthday</InputLabel>
                      <Input
                        id="birthday"
                        type="date"
                        {...register("birthday")}
                      />
                      <FormHelperText>
                        {errors?.birthday?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField 
                      label="Address" 
                      id="address" 
                      helperText={errors?.address?.message}
                      error={Boolean(errors?.address)}
                      fullWidth
                      multiline
                      rows={3}
                      {...register("address")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TimasDropzone 
                      onDrop={handleFileDrop}
                      inputProps={{
                        multiple: false,
                        accept: "application/pdf",
                        ...register("cvUrl")
                      }}
                      file={file}
                      rejectionMessage="Please only input PDF file"
                      error={errors?.cvUrl}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={
                        process.env.NODE_ENV === "development"
                          ? process.env.REACT_APP_RECAPTCHA_SITEKEY_TEST
                          : process.env.REACT_APP_RECAPTCHA_SITEKEY
                      }
                      onChange={() =>
                        setIsCaptchaChecked(
                          (prevIsCaptchaChecked) => !prevIsCaptchaChecked
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      disabled={!isCaptchaChecked || isSubmitting}
                      type="submit"
                    >
                      {
                        isSubmitting ? (
                          <CircularProgress color="inherit" size={16}/>
                        ) : "Submit Application"
                      }
                    </Button>
                  </Grid>
                </Grid>
              </form>
            ) : (
              <Box sx={{
                textAlign: "center",
                pt: 4
              }}>
                <Typography 
                  variant="h5" 
                  color={timasColor.primary.dark} 
                  textAlign="center"
                  marginBottom={4}
                >
                  Thank you for your application. We will get in touch with you promptly.
                </Typography>
                <Button 
                  fullWidth={sc.upToMd}
                  onClick={() => handleToggle()}
                >
                  Alright, Thank You!
                </Button>
              </Box>
            )
          }
        </Container>
      </AccordionDetails>
    </Accordion>
  );
}

export default ApplyCareer;