import { Box, Fade } from '@mui/material';
import React from 'react';
import Helmet from 'react-helmet';
import timasLogoMobile from 'images/timas_logo.png'

function PageScaffold(props) {
  const { children, sx } = props;
  
  return (
    <Box
      sx={{
        ...sx,
        minHeight: "100vh"
      }}
    >
      <Helmet titleTemplate='%s - Timas'>
        <title>Loading</title>
        <meta property="og:title" content="Timas Homepage" />
        <meta property="og:image" content={timasLogoMobile} />
        <meta
          name="description"
          content="Timas is a Leading Indonesia Private Company that provides dynamic and innovative Onshoe and Offshore EPCIC Service"
        />
        <meta
          name="og:description"
          content="Timas is a Leading Indonesia Private Company that provides dynamic and innovative Onshoe and Offshore EPCIC Service"
        />
      </Helmet>
      <Fade in={true} unmountOnExit>
        <div>
          {children}
        </div>
      </Fade>
    </Box>
  );
}

export default PageScaffold;