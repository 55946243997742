import { useMediaQuery, useTheme } from '@mui/material';

export default function useScreenClass() {
  const theme = useTheme();
  const upToXs = useMediaQuery(theme.breakpoints.down("xs"));
  const upToSm = useMediaQuery(theme.breakpoints.down("sm"));
  const upToMd = useMediaQuery(theme.breakpoints.down("md"));
  const upToLg = useMediaQuery(theme.breakpoints.down("lg"));
  const upToXl = useMediaQuery(theme.breakpoints.down("xl"));
  
  const downToXs = useMediaQuery(theme.breakpoints.up("xs"));
  const downToSm = useMediaQuery(theme.breakpoints.up("sm"));
  const downToMd = useMediaQuery(theme.breakpoints.up("md"));
  const downToLg = useMediaQuery(theme.breakpoints.up("lg"));
  const downToXl = useMediaQuery(theme.breakpoints.up("xl"));

  return {
    upToXs,
    upToSm,
    upToMd,
    upToLg,
    upToXl,
    downToXs,
    downToSm,
    downToMd,
    downToLg,
    downToXl,
  }
}
