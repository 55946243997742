import {
  Alert,
  Box,
  CircularProgress,
  Snackbar,
  Typography
} from "@mui/material";
import Footer from "components/Footer";
import Navbar from "components/Navbar";
import ScrollToTop from "components/ScrollToTop";
import { timasLogoOnly } from "components/timasLogos";
import Error404Page from "pages/Error404Page";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "routes";

const loading = (
  <Box
    sx={{
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center"
    }}
  >
    <img alt="Timas Suplindo" width="128px" src={timasLogoOnly} />
    <CircularProgress
      color="secondary"
      sx={{
        marginTop: "32px",
        marginRight: "52px"
      }}
    />
  </Box>
);

function AppRoutes(props) {
  const dispatch = useDispatch();
  const [toastState, setToastState] = useState({
    message: "",
    severity: "success"
  });
  const toast = useSelector(state => state.toast);

  useEffect(() => {
    if (toast) {
      setToastState({
        message: toast.message,
        severity: toast.severity
      });
    }
  }, [toast]);

  function handleCloseToaster() {
    dispatch({ type: "toaster/close" });
  }

  return (
    <ScrollToTop>
      <Snackbar open={Boolean(toast)} onClose={handleCloseToaster}>
        <Alert severity={toastState.severity} onClose={handleCloseToaster}>
          <Typography>{toastState.message}</Typography>
        </Alert>
      </Snackbar>
      <Navbar />
      <React.Suspense fallback={loading}>
        <Routes>
          {routes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={route.element || <Error404Page />}
            />
          ))}
          <Route path="/404" element={<Error404Page />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </React.Suspense>
      <Footer />
    </ScrollToTop>
  );
}

export default AppRoutes;
