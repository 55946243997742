import CareersDetail from "pages/CareerDetailsPage";
import FabricationPage from "pages/FabricationPage";
import HSSEPage from "pages/HSSEPage";
import ProjectDetailsPage from "pages/ProjectDetailsPage";
import React from "react";

const AboutUsPage = React.lazy(() => import("pages/AboutUsPage"));
const ContactUsPage = React.lazy(() => import("pages/ContactUsPage"));
const LandingPage = React.lazy(() => import("pages/LandingPage"));
const ServicesPage = React.lazy(() => import("pages/ServicesPage"));
const AwardsPage = React.lazy(() => import("pages/AwardsPage"));
const ProjectsPage = React.lazy(() => import("pages/ProjectsPage"));
const CareersPage = React.lazy(() => import("pages/CareersPage"));
const FleetDLB01Page = React.lazy(() => import("pages/Fleets/FleetDLB01Page"));
const FleetT1201Page = React.lazy(() => import("pages/Fleets/FleetT1201Page"));
const FPUPage = React.lazy(() => import("pages/Fleets/FPUPage"));
const FoamingPage = React.lazy(() => import("pages/Fleets/FoamingPage"));
//const Fleet = React.lazy(()=> import("pages/Fleets"));

const routes = [
  { path: "/", name: "Home", element: <LandingPage /> },
  { path: "/careers", name: "Careers", element: <CareersPage /> },
  {
    path: "/careers/:jobId",
    name: "Career Details",
    element: <CareersDetail />
  },
  { path: "/about", name: "About Us", element: <AboutUsPage /> },
  {
    path: "/about/fleetDLB01",
    name: "Timas DLB01",
    element: <FleetDLB01Page />
  },
  {
    path: "/about/fleetT1201",
    name: "Timas T1201",
    element: <FleetT1201Page />
  },
  {
    path: "/about/fabrication-shop",
    name: "Fabrication Shop",
    element: <FabricationPage />
  },
  { path: "/about/FPU", name: "FPU", element: <FPUPage /> },
  { path: "/about/Foaming", name: "Foaming", element: <FoamingPage /> },
  { path: "/services", name: "Services", element: <ServicesPage /> },
  { path: "/contact-us", name: "Contact Us", element: <ContactUsPage /> },
  { path: "/awards", name: "Awards", element: <AwardsPage /> },

  { path: "/projects", name: "Projects", element: <ProjectsPage /> },
  {
    path: "/projects/:projectId",
    name: "Project Details",
    element: <ProjectDetailsPage />
  },
  {
    path: "/hsse-management-system",
    name: "HSSE Management System",
    element: <HSSEPage />
  }
];

export default routes;
