import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { timasLogoOnly } from './timasLogos';

function TimasSpinner() {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
        <img
          alt="Timas Suplindo"
          width="128px"
          src={timasLogoOnly}
        />
        <CircularProgress
          color="secondary" 
          sx={{
            marginTop: "32px",
            marginRight: "52px"
          }}
        />
    </Box>
  );
}

export default TimasSpinner;