import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import timasColor from './timasColor';
import useScreenClass from '../hooks/useScreenClass';

function TimasHeader(props) {
  const { children, component, text, variant, color, ...rest } = props;
  const { sx, ...rest1 } = rest;
  const sc = useScreenClass();

  return (
    <Box
      sx={{
        borderLeft: sc.downToLg && `8px solid ${timasColor.primary.dark}`,
        paddingLeft: sc.downToLg && "24px",
        mb: 4,
        ...sx
      }}
      {...rest1}
    >
      {
        typeof children === "string" || !children ? (
          <Typography 
            component={component}
            color={timasColor.primary.dark} 
            variant={ variant ? !sc.upToMd ? variant : "h4" : "h4" } 
          >
            {children || text}
          </Typography>
        ) : (
          {children}
        )
      }
    </Box>
  );
}

export default TimasHeader;