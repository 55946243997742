import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import TimasHeader from 'components/TimasHeader';
import Markdown from "markdown-to-jsx";
import React, { useEffect, useState } from 'react';

function ProjectBodyMarkdown(props) {
  // const rootRef = useRef();
  // const highlightRef = useRef();
  const [body, setBody] = useState(props.body || "");

  useEffect(() => {
    if (props.body) {
      let unescaped = props.body.split(/[\s]+/g);
      unescaped = unescaped.filter((sentence) => sentence.includes("http"));
      unescaped = unescaped.map((link) => link.slice(link.search("http"), -1));
      let escaped = unescaped?.map((link) => link.replace(/\/\)/g, "\\)"));
      let processed = props.body;
      for (let i = 0; i < unescaped.length; i++) {
        processed = processed.replace(unescaped[i], escaped[i]);
      }
      setBody(processed);
    }
  }, [props.body]);
  
  return (
    <Markdown
      options={{
        disableParsingRawHTML: true,
        overrides: {
          h1: (props) => (
            <TimasHeader
              variant="h2"
              component="h2"
              id={props.id}
              className={props.className}
            >
              {props.children[0]}
            </TimasHeader>
          ),
          h2: (props) => (
            <TimasHeader
              variant="h3"
              component="h3"
              id={props.id}
              className={props.className}
            >
              {props.children[0]}
            </TimasHeader>
          ),
          h3: (props) => (
            <TimasHeader
              variant="h4"
              component="h4"
              id={props.id}
              className={props.className}
            >
              {props.children[0]}
            </TimasHeader>
          ),
          h4: (props) => (
            <TimasHeader
              variant="h5"
              component="h5"
              id={props.id}
              className={props.className}
            >
              {props.children[0]}
            </TimasHeader>
          ),
          h5: (props) => (
            <TimasHeader
              variant="h6"
              component="h6"
              id={props.id}
              className={props.className}
            >
              {props.children[0]}
            </TimasHeader>
          ),
          h6: (props) => (
            <TimasHeader
              variant="h6"
              component="h6"
              id={props.id}
              className={props.className}
            >
              {props.children[0]}
            </TimasHeader>
          ),
          p: (props) => (
            <Typography
              component="p"
              id={props.id}
              className={props.className}
              sx={{
                mb: 4
              }}
            >
              {props.children}
            </Typography>
          ),
          ol: (props) => (
            <Typography
              component="ol"
              id={props.id}
              className={props.className}
            >
              {props.children}
            </Typography>
          ),
          ul: (props) => (
            <Typography
              component="ul"
              id={props.id}
              className={props.className}
              sx={{
                mb: 4
              }}
            >
              {props.children}
            </Typography>
          ),
          img: {
            component: (imgProps) => (
              <img
                align="center"
                alt="Project Details"
                style={{
                  margin: "30px auto",
                  display: "flex",
                  maxWidth: "100%",
                }}
                {...imgProps}
              />
            ),
          },
          a: (props) => (
            <a
              {...props}
              rel={!props.href.includes("timas") ? "ugc nofollow" : null}
              target="_blank"
            >
              {props?.children}
            </a>
          ),
        }
      }}
    >
      {body}
    </Markdown>
  );
}

export default ProjectBodyMarkdown;