import { Breadcrumbs, Link, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import routes from 'routes';
import timasColor from './timasColor';

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

function TimasBreadcrumbs(props) {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter(x => x);
  const { lastTitle } = props;
  return (
    <Breadcrumbs separator=" • " sx={{ 
      paddingTop: "24px", 
      paddingBottom: "24px",
      borderBottom: `1px solid #2f2f2f`
    }}>
      <LinkRouter to="/" underline="hover" color={timasColor.black}>
        Home
      </LinkRouter>
      {
        pathnames.map((value, idx) => {
          const last = idx === pathnames.length - 1;
          const to = `/${pathnames.slice(0, idx + 1).join("/")}`;
          const name = routes.filter(r => r.path === to)?.[0]?.name;

          return last ? (
            <Typography color={timasColor.secondary.main} key={to}>
              {lastTitle || name}
            </Typography>
          ) : (
            <LinkRouter to={to} key={to} underline="hover" color={timasColor.black}>
              {name}
            </LinkRouter>
          )
        })
      }
    </Breadcrumbs>
  );
}

export default TimasBreadcrumbs;