import { IconButton, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import timasColor from './timasColor';
import footerLinks from 'contents/footerLinks';

function Footer() {
  return (
    <Box
      sx={{
        width: "100%",
        background: timasColor.black,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: timasColor.white
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "24px"
        }}
        paddingY={8}
      >
        {
          footerLinks.map((l, idx) => (
            <Tooltip key={idx} title={l.name}>
              <IconButton
                color="inherit"
                component="a"
                href={l.url}
                target="_blank"
              >
                {l.logo}
              </IconButton>
            </Tooltip>
          ))
        }
      </Box>
      <Box
        sx={{
          background: "rgba(253, 253, 253, 0.05)",
          width: "100%",
          textAlign: "center"
        }}
        paddingY="24px"
      >
        <Typography variant="subtitle1">
          Copyright © 2022 PT. Timas Suplindo. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;