import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import createSimpleReducer from "../utils/createSimpleReducer";
import authUserReducer from "./authUserReducer";
import selfUserReducer from "./selfUserReducer";
import toastReducer from "./toastReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["authUser", "selfUser", "announcement"],
};

/**
 * This is where all the reducers each entity type has are combined.
 * Please add/edit/remove entity types accordingly based on back-end
 * specs.
 */
const rootReducer = persistReducer(
  persistConfig,
  combineReducers({
    authUser: authUserReducer,
    selfUser: selfUserReducer,
    toast: toastReducer,
    totalPages: createSimpleReducer("totalPage"),
    projects: createSimpleReducer("project"),
    clients: createSimpleReducer("client"),
    jobs: createSimpleReducer("job"),
    contacts: createSimpleReducer("contactInformation"),
    awards: createSimpleReducer("award"),
  })
);

export default rootReducer;
