import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from 'AppRoutes';
import { Box } from '@mui/system';
import { timasLogoOnly } from 'components/timasLogos';
import { CircularProgress } from '@mui/material';

const loading = (
  <Box
    sx={{
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center"
    }}
  >
      <img
        alt="Timas Suplindo"
        width="128px"
        src={timasLogoOnly}
      />
      <CircularProgress
        color="secondary" 
        sx={{
          marginTop: "32px"
        }}
      />
  </Box>
)

function App() {
  return (
    <Router>
      <React.Suspense
        fallback={loading}
      >
        <AppRoutes/>
      </React.Suspense>
    </Router>
  );
}

export default App;
