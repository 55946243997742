const selfUserReducer = (state = null, action) => {
  switch (action.type) {
    case `selfUsers/replace`: {
      const selfUserMap = action.payload;
      return Object.values(selfUserMap)[0];
    }
    case `selfUsers/destroy`: {
      return null;
    }
    default: {
      return state;
    }
  }
};

export default selfUserReducer;
