import { Button, Container, Typography } from '@mui/material';
import PageScaffold from 'components/PageScaffold';
import timasColor from 'components/timasColor';
import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { curveHorizontal } from 'components/timasImages';
import useScreenClass from 'hooks/useScreenClass';

function Error404Page(props) {
  const sc = useScreenClass();
  return (
    <PageScaffold sx={{
      background: `url(${curveHorizontal})`,
      backgroundPosition: "center center",
      backgroundSize: "cover",
      display: "flex",
      alignItems: "center"
    }}>
      <Helmet>
        <title>Page Not Found</title>
        <meta property="og:title" content="Page Not Found" />
        <meta
          name="description"
          content="The page you're looking for has either been moved, or doesn't exist"
        />
        <meta
          name="og:description"
          content="The page you're looking for has either been moved, or doesn't exist"
        />
      </Helmet>
      <Container>
        <Typography variant="h2" color={timasColor.primary.dark} marginBottom={4}>
          404 <br/> Not Found
        </Typography>
        <Typography marginBottom={4}>
        The page you're looking for has either been moved, or doesn't exist.
        </Typography>
        <Button 
          fullWidth={sc.upToMd}
          component={Link}
          to="/"
        >
          Back to Homepage
        </Button>
      </Container>
    </PageScaffold>
  );
}

export default Error404Page;