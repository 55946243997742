import buildQueryStr from "../utils/buildQueryStr";
import {
  ACTION_TYPES,
  HTTP_METHODS,
  makeApiRequestThunk,
} from "./makeApiRequest";
import { makeApiRequestFile } from "./makeApiRequestFile";

class ApiCallActionCreator {
  sendMessage(message) {
    return makeApiRequestThunk(
      HTTP_METHODS.POST,
      `/messages`,
      message,
      ACTION_TYPES.MERGE
    )
  }

  // JOBS API
  getJobs(page, page_size, sort_by, division) {
    return makeApiRequestThunk(
      HTTP_METHODS.GET,
      buildQueryStr(`/jobs`, {
        page,
        page_size,
        sort_by,
        division
      }),
      null,
      ACTION_TYPES.MERGE
    )
  }

  getJobsCount(division) {
    return makeApiRequestThunk(
      HTTP_METHODS.GET,
      buildQueryStr(`/jobs/totalJobsCount`, {
        division
      }),
      null,
      ACTION_TYPES.MERGE
    )
  }

  getJob(jobId) {
    return makeApiRequestThunk(
      HTTP_METHODS.GET,
      `/jobs/${jobId}`,
      null,
      ACTION_TYPES.MERGE
    )
  }

  // APPLICANTS API
  createApplicant(applicant) {
    return makeApiRequestThunk(
      HTTP_METHODS.POST,
      `/applicants`,
      applicant,
      ACTION_TYPES.MERGE
    )
  }

  createApplicantCV(applicant) {
    return makeApiRequestThunk(
      HTTP_METHODS.POST,
      `/applicants/dropcv`,
      applicant,
      ACTION_TYPES.MERGE
    )
  }

  // PROJECTS API
  getProjects(
    page, 
    page_size, 
    sort_by,
    name,
    division,
    location,
    clientId
  ) {
    return makeApiRequestThunk(
      HTTP_METHODS.GET,
      buildQueryStr(`/projects`, {
        page, 
        page_size, 
        sort_by,
        name_contains: name,
        division,
        location,
        clientId
      }),
      null,
      ACTION_TYPES.MERGE
    );
  }

  getProjectsCount(name_contains, division, location, client_id) {
    return makeApiRequestThunk(
      HTTP_METHODS.GET,
      buildQueryStr(`/projects/totalProjectsCount`, {
        name_contains, division, location, client_id
      }),
      null,
      ACTION_TYPES.MERGE
    );
  }

  getProject(projectId) {
    return makeApiRequestThunk(
      HTTP_METHODS.GET,
      `/projects/${projectId}`,
      null,
      ACTION_TYPES.MERGE
    );
  }

  // CLIENTS API
  getClients(page, page_size, sort_by, name_contains) {
    return makeApiRequestThunk(
      HTTP_METHODS.GET,
      buildQueryStr(`/clients`, {
        page, 
        page_size,
        sort_by,
        name_contains
      }),
      null,
      ACTION_TYPES.MERGE
    );
  }

  getClientsCount(name_contains) {
    return makeApiRequestThunk(
      HTTP_METHODS.GET,
      buildQueryStr(`/clients/totalClientsCount`, {
        name_contains
      }),
      null,
      ACTION_TYPES.MERGE
    );
  }

  // CONTACTS API
  getContactInformations(page, page_size) {
    return makeApiRequestThunk(
      HTTP_METHODS.GET,
      buildQueryStr(`/contactinfo`, {
        page, page_size
      }),
      null,
      ACTION_TYPES.MERGE
    );
  }

  // AWARDS API
  getAwards(page, page_size, sort_by, title, year) {
    return makeApiRequestThunk(
      HTTP_METHODS.GET,
      buildQueryStr(`/awards`, {
        page, page_size, sort_by, title, year
      }),
      null,
      ACTION_TYPES.MERGE
    );
  }

  getAwardsCount(title, year) {
    return makeApiRequestThunk(
      HTTP_METHODS.GET,
      buildQueryStr(`/awards/totalAwardsCount`, {
        title, year
      }),
      null,
      ACTION_TYPES.MERGE
    );
  }

  getAwardsYears() {
    return makeApiRequestThunk(
      HTTP_METHODS.GET,
      `/awards/years`,
      null,
      ACTION_TYPES.MERGE
    );
  }

  // FILES API
  uploadResume(file) {
    return makeApiRequestFile(
      HTTP_METHODS.POST,
      `/files/resume`,
      file,
      ACTION_TYPES.MERGE
    )
  }
}

const fromApi = new ApiCallActionCreator();

export default fromApi;
