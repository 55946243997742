const {
  AiOutlineFacebook,
  AiOutlineYoutube,
  AiOutlineLinkedin,
  AiOutlineInstagram
} = require("react-icons/ai");
const { FiTwitter } = require("react-icons/fi");

const footerLinks = [
  {
    name: "Facebook",
    logo: <AiOutlineFacebook />,
    url: "https://www.facebook.com/PT-Timas-Suplindo-110042651836562"
  },
  {
    name: "Twitter",
    logo: <FiTwitter />,
    url: "https://twitter.com/timas_id"
  },
  {
    name: "YouTube",
    logo: <AiOutlineYoutube />,
    url: "https://www.youtube.com/c/PTTimasSuplindo"
  },
  {
    name: "Instagram",
    logo: <AiOutlineInstagram />,
    url: "https://www.instagram.com/timas.id"
  },
  {
    name: "LinkedIn",
    logo: <AiOutlineLinkedin />,
    url: "https://www.linkedin.com/company/pt-timas-suplindo/"
  }
];

export default footerLinks;
