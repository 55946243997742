import { Container, Grid, Slide, Typography } from "@mui/material";
import fromApi from "actions/fromApi";
import PageScaffold from "components/PageScaffold";
import timasColor from "components/timasColor";
import TimasHeader from "components/TimasHeader";
import TimasHero from "components/TimasProject";
import {
  curveHorizontal,
  onshore4,
  prism1,
  prism2,
  projectsPageBackground
} from "components/timasImages";
import TimasSpinner from "components/TimasSpinner";
import TransitionWhenVisible from "components/TransitionWhenVisible";
import useFromApi from "hooks/useFromApi";
import useResourceMapper from "hooks/useResourceMapper";
import useScreenClass from "hooks/useScreenClass";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EffectFade, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectBodyMarkdown from "./ProjectBodyMarkdown";

function ProjectDetails() {
  const { projectId } = useParams();
  const sc = useScreenClass();

  const projectApi = useFromApi(
    fromApi.getProject(projectId),
    [projectId],
    () => projectId
  );
  const project = useResourceMapper("projects", projectApi.sortOrder)?.[0];
  const client = useSelector(state => state.clients)?.[project?.clientId];

  return (
    <PageScaffold>
      <Helmet>
        <title>{project ? project.name : "Project Details"}</title>
      </Helmet>
      {!projectApi.loading ? (
        project ? (
          <>
            <Helmet>
              <meta
                property="og:image"
                content={
                  project.images.length ? project.images[0].url : onshore4
                }
              />
              <meta property="og:title" content={project.title} />
              <meta name="description" content={project.description} />
              <meta name="og:description" content={project.description} />
            </Helmet>
            <TimasHero backgroundUrl={projectsPageBackground}>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={project ? 6 : 12}>
                  <Slide in direction="right">
                    <div>
                      <Typography
                        variant="h6"
                        color={timasColor.white}
                        sx={{ paddingBottom: "16px" }}
                      >
                        Project
                      </Typography>
                      <Typography
                        variant="h4"
                        component="p"
                        color={timasColor.white}
                      >
                        {project.name}
                      </Typography>
                    </div>
                  </Slide>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Slide in direction="left">
                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Typography
                          variant="overline"
                          color={timasColor.white}
                          marginBottom={0.5}
                        >
                          Division
                        </Typography>
                        <Typography variant="h6" color={timasColor.white}>
                          {project.division}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Typography
                          variant="overline"
                          color={timasColor.white}
                          marginBottom={0.5}
                        >
                          Duration
                        </Typography>
                        <Typography variant="h6" color={timasColor.white}>
                          {project.duration}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Typography
                          variant="overline"
                          color={timasColor.white}
                          marginBottom={0.5}
                        >
                          Location
                        </Typography>
                        <Typography variant="h6" color={timasColor.white}>
                          {project.location}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Typography
                          variant="overline"
                          color={timasColor.white}
                          marginBottom={0.5}
                        >
                          Client
                        </Typography>
                        <Typography variant="h6" color={timasColor.white}>
                          {client?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Slide>
                </Grid>
              </Grid>
            </TimasHero>
            <article>
              <TransitionWhenVisible>
                <Container
                  sx={{
                    mt: 5,
                    py: 8,
                    backgroundImage: `url(${curveHorizontal})`,
                    backgroundSize: "cover"
                  }}
                >
                  <Grid container columnSpacing={12} rowSpacing={8}>
                    {project.images?.length ? (
                      <Grid item xs={12} sm={12} md={6}>
                        <Swiper
                          pagination={{
                            clickable: true
                          }}
                          navigation={{
                            clickable: true
                          }}
                          effect="fade"
                          slidesPerView="auto"
                          centeredSlides={true}
                          spaceBetween={30}
                          modules={[Pagination, Navigation, EffectFade]}
                        >
                          {project.images.map((i, idx) => (
                            <SwiperSlide key={idx}>
                              <img
                                src={i.url}
                                alt={project.name}
                                style={{
                                  width: "100%",
                                  height: "300px",
                                  objectFit: "cover"
                                }}
                              />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </Grid>
                    ) : null}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={project.images?.length ? 6 : 12}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                      }}
                    >
                      <TimasHeader text="About Project" sx={{ mb: 3 }}>
                        
                      </TimasHeader>
                      <Typography>{project.description}</Typography>
                    </Grid>
                  </Grid>
                </Container>
              </TransitionWhenVisible>
              {project.projectHeadline?.length ? (
                <Container
                  sx={{
                    background: `url(${prism1}) -45px bottom, url(${prism2}) right -60px top, ${timasColor.primary.gradient()}`,
                    backgroundSize: "27%, 20%, 100%",
                    backgroundRepeat: "no-repeat",
                    mt: 9
                  }}
                >
                  <TransitionWhenVisible>
                    <Grid
                      container
                      sx={{ pb: 5, pt: 1, px: sc.upToMd && 6 }}
                      spacing={4}
                    >
                      {project.projectHeadline.map((h, idx) => (
                        <Grid
                          item
                          key={idx}
                          xs={12}
                          sm={12}
                          md={project.projectHeadline.length <= 4 ? 6 : 4}
                        >
                          <Typography
                            variant="overline"
                            color={timasColor.white1}
                            marginBottom={0.5}
                          >
                            {h.label}
                          </Typography>
                          <Typography variant="h4" color={timasColor.white}>
                            {h.value}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </TransitionWhenVisible>
                </Container>
              ) : null}
              <TransitionWhenVisible>
                <Container sx={{ pb: 12, pt: 3 }}>
                  <ProjectBodyMarkdown body={project.body} />
                </Container>
              </TransitionWhenVisible>
            </article>
          </>
        ) : (
          <TimasHero title="Not Found" description="Project is not found" />
        )
      ) : (
        <TimasSpinner />
      )}
    </PageScaffold>
  );
}

export default ProjectDetails;
